export const websiteVersion = '9.5.10';

export const websiteVersionChanges = [
    '0.0.0 - Website is ready',
    '1.0.0 - The project now uses routes to navigate within a page. Design is also changed of almost all pages including reduction of corder radius and box shadow',
    '2.0.0 - Multiple sports are now supported',
    '3.0.0 - Major overhaul to the structure of TeamObject and GlobalState. Socials and Website are now part of TeamObject. All TeamObject is now saved in globalstate. When someone logs in, the new structure is added to their TeamObject',
    '4.0.0 - Payments enabled',
    '5.0.0 - Revamped the website. Background color of the website is now white',
    '6.0.0 - Design overhaul of all pages',
    '7.0.0 - Store is now setup. Barring few bugs and improvement',
    '8.0.0 - Lacrosse support added',
    '8.17.0 - HGPS brochure and feature partner tile added',
    '8.18.0 - Jog and HGPS tiles added under marketplace as vendors',
    '8.19.0 - OptimX Shipping Type and Shipping price included in product',
    '8.19.1 - Hockey gear pro shop tile now has team name',
    '8.19.2 - Hockey gear pro shop has more brochures and are now square',
    '8.19.3 - Added internal OptimX Sports Shipping Type',
    '8.19.4 - The navbar biw has carousel which shows multiple notification',
    '8.19.5 - Special url created for HGPS popup',
    '8.19.6 - Marketplace and Brands title in dashboard are now hyperlinked to marketplace',
    '8.19.7 - Store, Tickets & Donations in navlink changed to Store, Tickets & Registration Forms',
    '8.19.8 - Updates to product popup to accommodate cahoot shipping changes',
    '8.19.9 - Change Trouble Logging In text to Reset Password on login screen',
    '8.19.10 - Charges and Sold products popup now shows shipping fee and platform fee separately',
    '8.19.11 - Vendor Sales report now includes shipping charges as well',
    '8.19.12 - Shipping price for above 1lbs changed from $19.99 to $13.99',
    '8.19.13 - Product dimensions are not checked when saving. Can be 0 now',
    '8.19.14 - There was a bug where old products could not be modified because shipping vendor was automatically set to none',
    '8.19.15 - Another shipping vendor added for the non-shippable case',
    '8.19.16 - When non-shippable selected, prices, weight and dimcneions is changed to 0',
    '8.19.17 - Shippable toggle is now disabled when product is saved',
    '8.19.18 - All toggles will not show arrow next to it if they are disabled',
    '8.19.19 - Disputes added',
    '8.19.20 - Add store links to production website',
    '8.19.21 - Add option to convert between store and website option in settings to production website',
    '8.19.22 - All stripe dashboard links now use account id in the link as well',
    '8.19.23 - Taxable in forms was not refelecting the right taxable value',
    '8.19.24 - Ability to edit sold product details is now added',
    '8.19.25 - Report buttons in sold products now added in production',
    '8.19.26 - Sales report terminologies changed',
    '8.19.27 - Price added as helper text in all places',
    '8.19.28 - Activate store toggles are moved above in store settings',
    '8.19.29 - Unit price removed from vendor, was causing confusion',
    '8.19.30 - Date selection is removed from notify customers as it was actually using the date and search from the sold products and tickets screen',
    '8.19.31 - The Cahoot Shipping popup also notifies the customers now',
    '8.19.32 - The HGCP popup not only includes Nike but also includes Adidas and Under Armour',
    '8.19.33 - Bug fixes to cahoot shipping popup',
    '8.19.34 - Signin notification email added again',
    '8.19.35 - Navbar now scrolls',
    '8.19.36 - Bug in few stripe links',
    '8.19.37 - JOG popup and 2 Team Pants, removed one',
    '8.19.38 - Moved vendors to top in Marketplace',
    '8.19.39 - Earlier shipping details were not visible in prod version. Now visible.',
    '8.19.40 - Shipping carrier, number and url is now added to sold products',
    '8.19.41 - Feature image 1 removed from store instance because it will be removed from receipts',
    '8.19.42 - SEO added in store',
    '8.19.43 - HGPS fundraising tile added in featured partners',
    '8.19.44 - Calendly feature added in featured partners',
    '8.19.45 - The HGPS fundraising popup is now shown on dashboard first time',
    '8.19.46 - Status added in sold products',
    '8.19.47 - Google Info navlink changed to Google Info(SEO)',
    '8.19.48 - Feature image 1 added back to store instance because it will be shown in sharable links',
    '8.19.49 - Price breakdown added in product',
    '8.19.50 - Bug resolved in product sales report',
    '8.19.51 - Added total net store eatnings in product sales report',
    '8.20.0 - Added unit pricing for products in store instance',
    '8.20.1 - Added price breakdown for products',
    '8.20.2 - Removed shipping cost from production',
    '8.20.3 - Cahoot shipping email is now sent to Jon as well',
    '8.20.4 - Bug resolved in new unit vs default pricing',
    '8.20.5 - Fixed cahoot and sold products reports and csv',
    '9.0.0 - Support for vendors added',
    '9.0.1 - Vendor shippingThroughOptimX changed to internal from none',
    '9.0.2 - SKU and vendor added in sold product popup',
    '9.0.3 - Team name added in company name of cahoor order data',
    '9.1.0 - Shipping IDs are now printed as pdf and are much faster',
    '9.2.0 - Banner text can now be changed from portal',
    '9.2.1 - Twitter url is changed from x.com to twitter.com when saving',
    '9.3.0 - Tiktok now supported',
    '9.3.1 - Bug in SKU in sold products fixed',
    '9.4.0 - Mailersend integration added for signin email',
    '9.4.1 - Mailersend bug fix',
    '9.4.2 - Cahoot, domain, feedback, future, licensing, question, review, signup, subscription moved to mailsender',
    '9.4.3 - Bulk and csv emails now combined in 1 interface. Bug fixes in emails. Signup to client, contact us, referral and vendor email added to mailsender',
    '9.5.0 - Deletion removed as deletion now done through tools',
    '9.5.1 - Schedule for all products now added',
    '9.5.2 - Bug fix for crashing when refunded',
    '9.5.3 - Bug fix where shipping price was added even when cahoot was not selected',
    '9.5.4 - Shipping ids column size changed',
    '9.5.5 - shipping price was set incorrectly even for products that were heavier than 1 lbs. Fixed',
    '9.5.6 - The sales data for cahoot was showing wrong item name',
    '9.5.7 - Shipping ID had bugs. Fixed',
    '9.5.8 - Cahoot order data bug fixed',
    '9.5.9 - Cost is now included in the products page',
    '9.5.10 - Products csv page options are now in single quotes',
];
